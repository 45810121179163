import { graphql, useFragment } from 'react-relay'

import type { usePricing_PricingConfig$key } from '__generated__/usePricing_PricingConfig.graphql'
import type { usePricing_viewer$key } from '__generated__/usePricing_viewer.graphql'
import type { LegacyPricing, Pricing } from 'lib/pricing/types'
import { createPriceFromWebPricingConfig } from 'lib/pricing/utils'

export const usePricing = ({
    viewer,
}: {
    viewer: usePricing_viewer$key
}): Pricing | LegacyPricing => {
    const { webPricingConfig } = useFragment(usePricingFragment, viewer)

    const yearly = useFragment<usePricing_PricingConfig$key>(
        PricingConfigFragment,
        webPricingConfig?.yearly,
    )
    const monthly = useFragment<usePricing_PricingConfig$key>(
        PricingConfigFragment,
        webPricingConfig?.monthly,
    )

    return {
        yearly: createPriceFromWebPricingConfig({
            webPricingConfig: yearly,
            interval: 'year',
            name: webPricingConfig.name,
        }),
        monthly: createPriceFromWebPricingConfig({
            webPricingConfig: monthly,
            interval: 'month',
            name: webPricingConfig.name,
        }),
    }
}

const usePricingFragment = graphql`
    fragment usePricing_viewer on Viewer @argumentDefinitions(slug: { type: "String" }) {
        webPricingConfig(slug: $slug) {
            name
            yearly {
                ...usePricing_PricingConfig
            }
            monthly {
                ...usePricing_PricingConfig
            }
        }
    }
`
const PricingConfigFragment = graphql`
    fragment usePricing_PricingConfig on PricingConfig {
        stripeId
        amount
        currency
        currencySymbol
        trialDays
        discount {
            discountPct
            couponDurationInMonths
            couponRepetition
            discountFixed {
                currencySymbol
                amount
            }
        }
    }
`
