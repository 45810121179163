/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usePricing_viewer = {
    readonly webPricingConfig: {
        readonly name: string;
        readonly yearly: {
            readonly " $fragmentRefs": FragmentRefs<"usePricing_PricingConfig">;
        };
        readonly monthly: {
            readonly " $fragmentRefs": FragmentRefs<"usePricing_PricingConfig">;
        };
    };
    readonly " $refType": "usePricing_viewer";
};
export type usePricing_viewer$data = usePricing_viewer;
export type usePricing_viewer$key = {
    readonly " $data"?: usePricing_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"usePricing_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "usePricing_PricingConfig"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePricing_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "WebPricingConfig",
      "kind": "LinkedField",
      "name": "webPricingConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricingConfig",
          "kind": "LinkedField",
          "name": "yearly",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricingConfig",
          "kind": "LinkedField",
          "name": "monthly",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = 'e9f52c178fb1798476f0c42a77aae8c5';
export default node;
