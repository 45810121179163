import React from 'react'

import { premiumAccountSignUp } from '../utils'

import { Button } from 'components/Button'
import Column from 'components/Column'
import Columns from 'components/Columns'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import { useRefPage } from 'lib/routing/useRefPage'
import connectedNetwork from 'public/images/connected-network.svg'
import financePuzzle from 'public/images/finance-puzzle.svg'
import magnifyChart from 'public/images/magnify-chart.svg'
import { fonts } from 'theme'

type BenefitsProps = {
    title?: string
    benefitsList?: {
        title: string
        text: string
        image: {
            src: string
            alt: string
        }
    }[]
}

const VALUE_PROPS: BenefitsProps['benefitsList'] = [
    {
        title: "Understand today's biggest stories",
        text: "Never miss a beat with today's latest market-moving headlines in under five minutes.",
        image: {
            src: financePuzzle,
            alt: 'Finance Puzzle Being Finished',
        },
    },
    {
        title: 'Spot under-the-radar investments',
        text: 'Uncover emerging trends and actionable strategies before everyone else.',
        image: {
            src: magnifyChart,
            alt: 'Magnify Investment Chart',
        },
    },
    {
        title: 'Become a Smarter Investor',
        text: "Start discovering new investing opportunities today that everyone else won't know about until tomorrow.",
        image: {
            src: connectedNetwork,
            alt: 'Connected Ideas',
        },
    },
]

const Benefits = (props: BenefitsProps) => {
    const {
        title = 'The insights, analysis, and tools you need to build your portfolio',
        benefitsList = VALUE_PROPS,
    } = props

    const refPage = useRefPage()
    const premiumAccountCTA = premiumAccountSignUp(refPage)

    return (
        <Box pb={['3rem', null, '6rem']}>
            <Container>
                <Columns>
                    <Column textAlign="center" spread={[12, 12, 10, 8]} offset={[0, 0, 1, 2]}>
                        <Heading as="h2" fontSize={['2rem', null, '2.5rem']}>
                            {title}
                        </Heading>
                    </Column>
                </Columns>
                <Columns mt="2rem">
                    {benefitsList.map(({ title, text, image }, i) => (
                        <Column textAlign="center" spread={[12, 12, 4]} key={i}>
                            <Box px={['1rem', null, '2rem']}>
                                <Image src={image.src} alt={image.alt || title} mb="2rem" />
                                <Heading as="h3" fontSize={fonts.size.XL} mb="1rem">
                                    {title}
                                </Heading>
                                <Paragraph>{text}</Paragraph>
                            </Box>
                        </Column>
                    ))}
                </Columns>
                <Box mt="2rem" display="flex" justifyContent="center">
                    <Button variant="secondary" url={premiumAccountCTA.url}>
                        {premiumAccountCTA.text}
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}

export default Benefits
