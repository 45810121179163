import { graphql, useFragment } from 'react-relay'

import type { useTagCloud_viewer$key } from '__generated__/useTagCloud_viewer.graphql'

export type TagProps = {
    name: string
}

export const useTagCloud = (viewerKey: useTagCloud_viewer$key): TagProps[] => {
    const viewer = useFragment(TagsFragment, viewerKey)

    return viewer.mostPopularTags.edges
        .map(tag => ({ name: tag.node.name || '' }))
        .filter(tag => !!tag.name)
}

const TagsFragment = graphql`
    fragment useTagCloud_viewer on Viewer {
        mostPopularTags(first: 15) {
            edges {
                node {
                    ... on AssetClassTag {
                        slug
                        name
                    }
                    ... on ContentTag {
                        slug
                        name
                    }
                    ... on GeographyTag {
                        slug
                        name
                    }
                }
            }
        }
    }
`
