import React from 'react'

import { premiumAccountSignUp } from '../utils'

import { Button } from 'components/Button'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import { useRefPage } from 'lib/routing/useRefPage'
import duoAppPhones from 'public/images/App_LightModeDynamic.png'
import finimizeLogoFullBrandWhite from 'public/images/finimize-logo-full-brand-white.png'
import { routeMap } from 'shared/routes'
import { zIndex } from 'theme'

type HeaderProps = {
    title?: string
    subtitle?: string
}

const JoinHeader = (props: HeaderProps) => {
    const {
        title = 'Become a smarter investor in minutes',
        subtitle = 'With key insights on the biggest news, opportunities, and strategies from a team of world-class analysts.',
    } = props

    const refPage = useRefPage()
    const premiumAccountCTA = premiumAccountSignUp(refPage)

    return (
        <Box
            background="linear-gradient(174.61deg, #252B38 7.44%, #020711 93.03%)"
            p={['2rem 0 0', null, '3rem 0 0']}
        >
            <Container>
                <Box
                    margin="0 auto"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    maxWidth="42rem"
                    textAlign="center"
                >
                    <Link url={routeMap.home}>
                        <Image
                            alt="Finimize logo"
                            src={finimizeLogoFullBrandWhite}
                            width="125px"
                            mb={1}
                            priority
                        />
                    </Link>
                    <Heading
                        color="white"
                        as="h1"
                        mb="1rem"
                        fontSize={['2rem', '2.5rem', '3.5rem']}
                    >
                        {title}
                    </Heading>

                    <Paragraph
                        pb="1rem"
                        color="white"
                        maxWidth="45ch"
                        fontSize={['1rem', null, '1.15rem']}
                    >
                        {subtitle}
                    </Paragraph>
                    <Button url={premiumAccountCTA.url} data-cy="join-header-cta">
                        {premiumAccountCTA.text}
                    </Button>
                </Box>
            </Container>
            <Box pb={['2rem', null, '2rem']} pt="2rem" position="relative">
                <Box
                    margin="0 auto"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    maxWidth="42rem"
                    textAlign="center"
                >
                    <Box
                        backgroundColor="white"
                        position="absolute"
                        left="0"
                        bottom="0"
                        height="50%"
                        width="100%"
                        zIndex={zIndex.layer01}
                    />
                    <Image
                        alt="Finimize iPhone app"
                        src={duoAppPhones}
                        width="100%"
                        maxWidth="1000px"
                        zIndex={zIndex.layer03}
                        priority
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default JoinHeader
