import type { useDefaultPlansPricing_viewer } from '__generated__/useDefaultPlansPricing_viewer.graphql'
import type { useLegacyPrice_viewer } from '__generated__/useLegacyPrice_viewer.graphql'
import type { usePricing_PricingConfig } from '__generated__/usePricing_PricingConfig.graphql'
import createPriceString from 'lib/createPriceString'
import type { Price } from 'lib/pricing/types'
import type { StripeInterval } from 'types/stripeConsts'
import { isAnnual } from 'types/stripeConsts'

export const createPriceFromWebPaymentPlan = (
    webPaymentPlan:
        | useLegacyPrice_viewer['webPaymentPlan']
        | useDefaultPlansPricing_viewer['monthly']['secondaryWebPlan']
        | useDefaultPlansPricing_viewer['annual'],
): Price => {
    const { stripeId, amount, currency, currencySymbol, interval, nickname } = webPaymentPlan
    const promotionApplied =
        'promotionApplied' in webPaymentPlan ? webPaymentPlan.promotionApplied : null

    return {
        id: stripeId,
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        interval: interval as StripeInterval,
        nickname: nickname,
        promotionApplied: {
            discountPct: promotionApplied?.stripeDiscount.discountPct || 0,
            discountAmount: promotionApplied?.stripeDiscount.discountAmount || 0,
            couponDurationInMonths: promotionApplied?.stripeDiscount.couponDurationInMonths || 0,
            couponRepetition: promotionApplied?.stripeDiscount.couponRepetition || 'ONCE',
        },
        trialDays: promotionApplied?.trialDays || 0,
    }
}

const calculateCouponDurationInMonths = (
    discount: NonNullable<usePricing_PricingConfig['discount']>,
    interval: Price['interval'],
) => {
    const { couponRepetition } = discount
    if (couponRepetition === 'ONCE') {
        // If the coupon is only applied once, we set the duration to the length of the interval
        return interval === 'year' ? 12 : 1
    }
    // Otherwise, we use the duration specified in the discount
    return discount.couponDurationInMonths
}

export const createPriceFromWebPricingConfig = ({
    webPricingConfig,
    interval,
    name,
}: {
    webPricingConfig: NonNullable<usePricing_PricingConfig>
    interval: Price['interval']
    name: string
}): Price => {
    const { stripeId, amount, currency, currencySymbol, trialDays, discount } = webPricingConfig

    const promotionApplied = discount
        ? {
              discountPct: discount.discountPct || 0,
              discountAmount: discount.discountFixed?.amount
                  ? discount.discountFixed.amount * 100
                  : 0,
              couponDurationInMonths: calculateCouponDurationInMonths(discount, interval) || 0,
              couponRepetition: discount.couponRepetition || 'ONCE',
          }
        : null

    return {
        id: stripeId,
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        interval: interval,
        nickname: name,
        promotionApplied: promotionApplied,
        trialDays: trialDays || 0,
    }
}

export const getPaywallPrice = (priceObject: Price) => {
    const { interval } = priceObject
    const annualPlan = !!interval && isAnnual(interval.toLowerCase())

    const hasDiscount =
        priceObject.promotionApplied?.discountPct || priceObject.promotionApplied?.discountAmount

    const calculatedMonthlyPriceDiscounted =
        (hasDiscount &&
            createPriceString({
                // as we're showing this per month
                amount: priceObject.amount,
                currency: priceObject.currency,
                currencySymbol: priceObject.currencySymbol,
                stripeDiscountPct: priceObject.promotionApplied?.discountPct || null,
                stripeDiscountAmount: priceObject.promotionApplied?.discountAmount || null,
                convertToMonth: annualPlan,
                hideCurrencyInfo: true,
            })) ||
        null

    const calculatedMonthlyPriceFull = createPriceString({
        // as we're showing this per month
        amount: priceObject.amount,
        currency: priceObject.currency,
        currencySymbol: priceObject.currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        convertToMonth: annualPlan,
        hideCurrencyInfo: true,
    })

    return { calculatedMonthlyPriceDiscounted, calculatedMonthlyPriceFull }
}

export const priceHasTrial = (priceObject: Price) => {
    return priceObject.trialDays > 0
}
