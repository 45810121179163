import type { CSSProperties, ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ThemedComponent } from 'theme'

/**
 *
 * @param header components to render as the tables header
 * @param rows components to render as the tables rows
 * Both inputs have optional style properties to override default cell styling
 * @returns an accessible table component
 */
const Table = ({
    header,
    rows,
}: {
    header: { component: ReactNode; style?: CSSProperties }[]
    rows: { component: ReactNode; style?: CSSProperties }[][]
}) => {
    return (
        <TableContainer padding={[0, 1]}>
            <StyledTable>
                <thead>
                    <tr>
                        {header.map((cell, index) => (
                            <th key={`header-${index}`} style={cell.style}>
                                {cell.component}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={`row-${rowIndex}`}>
                            {row.map((cell, columnIndex) => (
                                <td key={`cell-${rowIndex}-${columnIndex}`} style={cell.style}>
                                    {cell.component}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    )
}

type StyledProps = ThemedComponent<BoxProps>

const TableContainer = styled(Box)<StyledProps>`
    border: 2px solid ${({ theme }) => theme.palette.neutral[999]};
    box-shadow: 3px 3px 0 0 ${({ theme }) => theme.palette.neutral[999]};
    border-radius: ${({ theme }) => theme.radii.default};
`

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-spacing: 2px;

    td,
    th,
    tr,
    tbody,
    thead {
        border: 1px solid;
        border-spacing: 2px;
        border-color: ${({ theme }) => theme.palette.neutral[300]};
        border-radius: ${({ theme }) => theme.radii.default};
        text-align: center;
        padding: 8px;
    }
`

export default Table
