import { graphql } from 'react-relay'

import { useTagCloud } from '../../components/TagCloud/useTagCloud'

import { Benefits, Header, Plans, Pricing, Reviews } from './sections'

import type { JoinPageQueryResponse } from '__generated__/JoinPageQuery.graphql'
import { SEO_DOMAIN } from 'consts/index'
import SeoWrapper from 'layouts/SeoWrapper'
import { usePricing } from 'lib/pricing/usePricing'
import withData from 'lib/withData'
import BuilderComponent from 'modules/Builder'
import Footer from 'modules/Footer/Footer'
import { routeMap } from 'shared/routes'

const FallbackComponent = ({ viewer }: JoinPageQueryResponse) => (
    <>
        <Header />
        <Benefits />
        <Plans />
        <Pricing viewer={viewer} />
        <Reviews />
        <Footer />
    </>
)

const Join = ({ viewer }: JoinPageQueryResponse) => {
    const pricing = usePricing({ viewer })
    const tags = useTagCloud(viewer)

    return (
        <SeoWrapper
            seoProps={{
                title: 'Join Finimize Premium',
                titleTemplate: '%s - Finimize',
                description:
                    'Discover your next big investment idea with the insights, analysis, and tools you need to build your portfolio.',
                canonical: `${SEO_DOMAIN}${routeMap.join}`,
                noindex: false,
            }}
        >
            <BuilderComponent
                modelName="join-page"
                fallbackComponent={<FallbackComponent viewer={viewer} />}
                data={{ pricing, tags }}
            />
        </SeoWrapper>
    )
}

export default withData(Join, {
    query: graphql`
        query JoinPageQuery {
            viewer {
                ...useTagCloud_viewer
                ...usePricing_viewer
                ...Pricing_viewer
            }
        }
    `,
})
