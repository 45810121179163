import { graphql, useFragment } from 'react-relay'

import PricingUI from './PricingUI'

import type { Pricing_viewer$key } from '__generated__/Pricing_viewer.graphql'
import { isPricing } from 'lib/pricing/types'
import { usePricing } from 'lib/pricing/usePricing'

type Props = {
    viewer: Pricing_viewer$key
}

const Pricing = ({ viewer: viewerKey }: Props) => {
    const viewer = useFragment(PricingFragment, viewerKey)
    const pricingData = usePricing({ viewer })

    if (!pricingData || !isPricing(pricingData)) {
        return null
    }

    return <PricingUI pricingData={pricingData} />
}

const PricingFragment = graphql`
    fragment Pricing_viewer on Viewer @argumentDefinitions(slug: { type: "String" }) {
        ...usePricing_viewer @arguments(slug: $slug)
    }
`

export default Pricing
