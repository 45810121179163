/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useTagCloud_viewer = {
    readonly mostPopularTags: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly slug?: string | undefined;
                readonly name?: string | undefined;
            };
        }>;
    };
    readonly " $refType": "useTagCloud_viewer";
};
export type useTagCloud_viewer$data = useTagCloud_viewer;
export type useTagCloud_viewer$key = {
    readonly " $data"?: useTagCloud_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useTagCloud_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTagCloud_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 15
        }
      ],
      "concreteType": "GenericTagConnection",
      "kind": "LinkedField",
      "name": "mostPopularTags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GenericTagNonNullEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "AssetClassTag",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "ContentTag",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "GeographyTag",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "mostPopularTags(first:15)"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = 'b797a4f894fb0b631b81d6ff5da9a035';
export default node;
