export const PAGE_HEADER = '<?xml version="1.0" encoding="UTF-8"?>'

export const TAG_NAMES = {
    LOCATION: 'loc',
    LAST_MODIFIED: 'lastmod',
    CHANGE_FREQUENCY: 'changefreq',
    PRIORITY: 'priority',
    URL_SET: 'urlset',
    SITEMAP_INDEX: 'sitemapindex',
    SITEMAP: 'sitemap',
    URL: 'url',
}

const NEWS_PREFIX = 'news:'

export const NEWS_TAG_NAMES = {
    NEWS: NEWS_PREFIX + 'news',
    PUBLICATION: NEWS_PREFIX + 'publication',
    NAME: NEWS_PREFIX + 'name',
    LANGUAGE: NEWS_PREFIX + 'language',
    GENRES: NEWS_PREFIX + 'genres',
    PUBLICATION_DATE: NEWS_PREFIX + 'publication_date',
    TITLE: NEWS_PREFIX + 'title',
    KEYWORDS: NEWS_PREFIX + 'keywords',
    STOCK_TICKERS: NEWS_PREFIX + 'stock_tickers',
}
