import React from 'react'

import { premiumAccountSignUp } from '../utils'

import { Button } from 'components/Button'
import Card from 'components/Card'
import Column from 'components/Column'
import Columns from 'components/Columns'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import StarRating from 'components/StarRating'
import { useRefPage } from 'lib/routing/useRefPage'
import AppAwards from 'public/images/app-store-awards.png'

type Review = {
    title?: string
    review: string
    /** out of 5 */
    rating?: number
}

type ReviewsProps = {
    title?: string

    col1Reviews?: Review[]
    col2Reviews?: Review[]
}

const REVIEWS_FIRST: Review[] = [
    {
        title: 'Worth every cent!',
        review: 'The world of investing made plain.',
        rating: 5,
    },
    {
        title: 'Amazing platform for your financial education!',
        review: 'I’m a pro user and Finimize has simplified a lot of commonly used financial terms, processes, models, etc. There is a wealth of information to chose from and I would recommend this app to anyone who wants develop their financial knowledge base.',
        rating: 5,
    },
    {
        title: 'A must have.',
        review: 'Diversified and valuable insights, from the best sources you could ever find. I love the app, with the features of podcasts and the possibility to download the different articles. Everything done with such an user friendly interface. Simply perfect!',
        rating: 5,
    },
    {
        title: '5 minute updates',
        review: 'Great to be a mind opener on where to spend some money for investment, has brought me several ideas that even I can understand',
        rating: 5,
    },
]
const REVIEWS_SECOND: Review[] = [
    {
        title: 'Useful & profitable educating',
        review: 'With a few months in using this app I’ve learned a lot about the financial market as well I have increased my portfolio greatly. Awesome app!',
        rating: 5,
    },
    {
        title: 'One stop centre ',
        review: 'Finimize is where I go for my daily financial news! It gives me ideas on where to research further and also how to research.',
        rating: 5,
    },
    {
        title: 'Outstanding',
        review: 'I don’t know what I expected from Finimize when I first downloaded it but I have been amazed at Finimize seek interface and I absolutely love its 5-7 min news recaps. As a student I would normally have to decide if I’m gonna to study or study the stock trends but with Finimize I am able to listen on the go and choose what topics I want to hear about. Finimize allows me to be the investor of tomorrow',
        rating: 5,
    },
    {
        title: 'Solid, accessible financial advice written with brevity',
        review: '👍🏼👍🏼 This is the advice I expect to get from a financial advisor, but don’t get from them. Finimize is my go-to source for understanding market trends.',
        rating: 5,
    },
]

const Review = ({ title, review, rating, ...props }: Review) => (
    <Card
        bg="white"
        borderRadius="default"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.08)"
        padding={['1rem', null, '1.5rem']}
        {...props}
    >
        <Paragraph display="block" fontWeight="bold" color="black800">
            {title}
        </Paragraph>

        <Paragraph mt="-0.5rem">{review}</Paragraph>
        {rating && <StarRating rating={rating} />}
    </Card>
)

const Reviews = (props: ReviewsProps) => {
    const {
        title = 'Join 1 million+ others making smarter investing decisions',

        col1Reviews = REVIEWS_FIRST,
        col2Reviews = REVIEWS_SECOND,
    } = props

    const refPage = useRefPage()
    const premiumAccountCTA = premiumAccountSignUp(refPage)

    return (
        <Box py={['3rem', null, '6rem']} bg="grey200">
            <Container>
                <Columns>
                    <Column textAlign="center" spread={[12, 12, 10, 8]} offset={[0, 0, 1, 2]}>
                        <Heading as="h3" fontSize={['2rem', null, '2.5rem']}>
                            {title}
                        </Heading>
                    </Column>
                </Columns>
                <Box mt={['2rem', null, '3rem']}>
                    <Columns>
                        <Column spread={[12, 12, 6, 5]} offset={[0, 0, 0, 1]}>
                            {col1Reviews.map((props, i) => (
                                <Box padding={['0.5rem 0', null, '1rem 0']} key={i}>
                                    <Review {...props} />
                                </Box>
                            ))}
                        </Column>
                        <Column display={['none', null, 'block']} spread={[12, 12, 6, 5]}>
                            {col2Reviews.map((props, i) => (
                                <Box padding={['0.5rem 0', null, '1rem 0']} key={i}>
                                    <Review {...props} />
                                </Box>
                            ))}
                        </Column>
                    </Columns>
                </Box>
                <Box
                    mt="3rem"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Button url={premiumAccountCTA.url}>{premiumAccountCTA.text}</Button>
                </Box>
                <Image
                    alt="Finimize App Awards"
                    src={AppAwards}
                    width="80%"
                    maxWidth="280px"
                    mt={5}
                    mx="auto"
                />
            </Container>
        </Box>
    )
}

export default Reviews
