/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CouponDurationEnum = "FOREVER" | "ONCE" | "REPEATING" | "%future added value";
export type usePricing_PricingConfig = {
    readonly stripeId: string;
    readonly amount: number;
    readonly currency: string;
    readonly currencySymbol: string;
    readonly trialDays: number | null;
    readonly discount: {
        readonly discountPct: number | null;
        readonly couponDurationInMonths: number | null;
        readonly couponRepetition: CouponDurationEnum | null;
        readonly discountFixed: {
            readonly currencySymbol: string;
            readonly amount: number;
        } | null;
    } | null;
    readonly " $refType": "usePricing_PricingConfig";
};
export type usePricing_PricingConfig$data = usePricing_PricingConfig;
export type usePricing_PricingConfig$key = {
    readonly " $data"?: usePricing_PricingConfig$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"usePricing_PricingConfig">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencySymbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePricing_PricingConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeDiscount",
      "kind": "LinkedField",
      "name": "discount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountPct",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "couponDurationInMonths",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "couponRepetition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "discountFixed",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PricingConfig",
  "abstractKey": null
};
})();
(node as any).hash = '24eee107fdc3f1343269557569c44afa';
export default node;
