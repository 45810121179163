import type { ButtonProps } from 'components/Button'
import { routeMap } from 'shared/routes'

type ctaType = {
    url: ButtonProps['url']
    text: string
}

const JOIN_PAGE_CTA_SOURCE = 'join-page'

/**
 * Should only be used for links on the join page
 */
export const premiumAccountSignUp = (refPage?: string): ctaType => {
    const baseUrl = `${routeMap.checkout()}`

    return {
        url: refPage
            ? `${baseUrl}?ref-page=${encodeURIComponent(
                  refPage,
              )}&cta-source=${JOIN_PAGE_CTA_SOURCE}`
            : `${baseUrl}?cta-source=${JOIN_PAGE_CTA_SOURCE}`,
        text: 'Try Premium',
    }
}

/**
 * Should only be used for links on the join page
 */
export const addRefPageToUrl = (url: string, refPage?: string): string => {
    return refPage ? `${url}?ref-page=${encodeURIComponent(refPage)}` : `${url}`
}
