import { SEO_DOMAIN } from 'consts'
import { Priority, type MasterConfig } from 'lib/sitemap/types'

// Configuration file for the generation of the sitemap.
// Used in sitemap.xml.tsx
// Database dependent pages should be custom implemented in sitemap.xml.tsx
// https://www.sitemaps.org/protocol.html

export const config: MasterConfig = {
    baseUrl: SEO_DOMAIN,
    staticPages: [
        {
            extension: '',
            changeFrequency: 'hourly',
            priority: Priority.important,
        },
        {
            extension: 'news',
            changeFrequency: 'hourly',
            priority: Priority.high,
        },
        {
            extension: 'analysis',
            changeFrequency: 'daily',
            priority: Priority.high,
        },
        {
            extension: '/hubs/education',
            changeFrequency: 'daily',
            priority: Priority.high,
        },
        {
            extension: '/hubs/advisors',
            changeFrequency: 'daily',
            priority: Priority.high,
        },
        {
            extension: '/events',
            changeFrequency: 'daily',
            priority: Priority.high,
        },
        {
            extension: '/careers',
            changeFrequency: 'monthly',
            priority: Priority.normal,
        },
        {
            extension: '/terms',
            changeFrequency: 'yearly',
            priority: Priority.low,
        },
        // TODO: comment back in when glossary list is live
        // {
        //     extension: '/glossary',
        //     changeFrequency: 'yearly',
        //     priority: Priority.low,
        // },
        {
            extension: '/privacy',
            changeFrequency: 'yearly',
            priority: Priority.low,
        },
        {
            extension: '/faq',
            changeFrequency: 'monthly',
            priority: Priority.low,
        },
        {
            extension: '/about-us',
            changeFrequency: 'yearly',
            priority: Priority.normal,
        },
    ],
    news: {
        publication_name: 'Finimize',
        default_language: 'en',
        default_keywords: [],
    },
}
