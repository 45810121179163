import type { ServerResponse } from 'http'

/**
 * Indicates how frequently the page is changed. It is important to be as accurate as possible here
 * as this is used by crawlers to decide how frequently to index a page. If this is use incorrectly, if may
 * (although unlikely) have a negative impact on our site.
 *
 * There must be a very compelling case to use either 'always' or 'never'
 * */
export type ChangeFrequency =
    | 'always'
    | 'hourly'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'yearly'
    | 'never'

export type ISODateTime = string //ISODateTime: 2022-06-21T09:51:40.442Z

/**
 * Tells the crawler how important a site is to us relative to our other pages.
 * The values are arbitrary similar to z-index and give a relative priority index.
 * More steps can be added if a use case requires it.
 *
 * Usage:
 * This helps a crawler decide which pages to visit, so we should prioritise pages that are likely to change,
 * or have new/updated links. By default all pages start as normal. Priority 0 does not mean it is never crawled.
 *
 * Examples:
 *  Static: 404
 *  Low: Join
 *  Normal: Article
 *  High: *no active use case*
 *  Important: Article Feed
 */
export enum Priority {
    static = 0,
    low = 0.25,
    normal = 0.5,
    high = 0.75,
    important = 1,
}

// Block Types

export type PublicationBlockConfig = {
    name: string // Should always be Finimize for us.
    language: string // ISO 639 Language Code - Will almost always be: en
}

export type NewsBlockConfig = {
    publicationDate: ISODateTime
    title: string
    genres?: string[]
    keywords?: string[]
    stockTickers?: string[]
}

// URL or Link Config
export interface ExtensionLinkConfig {
    extension: string
}

export interface UrlLinkConfig {
    url: string
}

type ExtensionOrUrl = ExtensionLinkConfig | UrlLinkConfig

export function hasUrlLink<T>(block: T): block is T & UrlLinkConfig {
    return (block as T & UrlLinkConfig).url !== undefined
}

// Config for a page
export type UrlBlockConfig = {
    lastModified?: ISODateTime // undefined = datetime.now
    changeFrequency: ChangeFrequency
    priority: Priority
} & ExtensionOrUrl

export type UrlBlockWithNewsConfig = {
    lastModified?: ISODateTime // undefined = datetime.now
    changeFrequency: ChangeFrequency
    priority: Priority
    news: NewsBlockConfig
} & ExtensionOrUrl

// Config for a link to a sitemap to be imbedded in another sitemap
export interface SitemapBlockConfig {
    extension: string //omit starting "/": sitemap.xml
}

// master config type

export type MasterConfig = {
    baseUrl: string
    staticPages: UrlBlockConfig[]
    news: {
        publication_name: string
        default_language: string
        default_keywords: string[]
    }
}

// Sitemap Build and Render types

export type UrlSitemapType = 'urls'
export type NewsSitemapType = 'news'
export type SitemapSitemapType = 'sitemap'

export type SitemapTypename = UrlSitemapType | NewsSitemapType | SitemapSitemapType

interface BaseBuildSitemapParams<T extends SitemapTypename> {
    __typename: T
    config: Array<BlockConfig<T>>
}

// conditionally selects the block type available for each sitemap __typename
type BlockConfig<T extends SitemapTypename> = T extends SitemapSitemapType
    ? SitemapBlockConfig
    : T extends NewsSitemapType
    ? UrlBlockWithNewsConfig
    : UrlBlockConfig

type UrlSitemapConfig = BaseBuildSitemapParams<UrlSitemapType>
type NewsSitemapConfig = BaseBuildSitemapParams<NewsSitemapType>
type SitemapSitemapConfig = BaseBuildSitemapParams<SitemapSitemapType>

export type BuildSitemapParams = UrlSitemapConfig | NewsSitemapConfig | SitemapSitemapConfig

export type RenderSitemapParams = { res: ServerResponse } & BuildSitemapParams
