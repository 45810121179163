import { addRefPageToUrl, premiumAccountSignUp } from '../utils'

import Button from 'components/Button'
import Column from 'components/Column'
import Columns from 'components/Columns'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import Table from 'components/Table'
import { useRefPage } from 'lib/routing/useRefPage'
import { routeMap } from 'shared/routes'
import { fonts } from 'theme'

const Plans = () => {
    const refPage = useRefPage()
    const premiumAccountCTA = premiumAccountSignUp(refPage)
    const freeAccountUrl = addRefPageToUrl(routeMap.signup, refPage)

    return (
        <Box
            textAlign="center"
            backgroundColor="primary.50"
            width="100vw"
            paddingY="3rem"
            paddingX={['2rem', null, '1rem']}
        >
            <Columns>
                <Column textAlign="center" spread={[12, 12, 10, 8]} offset={[0, 0, 1, 2]}>
                    <Heading as="h2">Choose the right plan for you</Heading>
                    <Heading
                        as="h3"
                        fontSize={fonts.size.M}
                        fontWeight={fonts.weight.normal}
                        color="primary.500"
                    >
                        Free & Premium plans available
                    </Heading>

                    <Table header={plansHeader} rows={plansRows} />

                    <Set
                        paddingY="3rem"
                        justifyContent="center"
                        spacing={2}
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Button variant="secondary" url={freeAccountUrl}>
                            Get free plan
                        </Button>
                        <Button variant="primary" url={premiumAccountCTA.url}>
                            {premiumAccountCTA.text}
                        </Button>
                    </Set>
                </Column>
            </Columns>
        </Box>
    )
}

const PremiumHeader = () => {
    return (
        <>
            <Box fontSize={fonts.size.M} fontWeight={fonts.weight.black}>
                Premium
            </Box>
            <Box fontSize={fonts.size.XS} fontWeight={fonts.weight.normal}>
                7 day trial
            </Box>
        </>
    )
}

const AvailableForFreeIcon = <Icon icon="RiCheckboxCircleFill" />
const AvailableForPremiumIcon = <Icon color="primary.500" icon="RiCheckboxCircleFill" />

const plansHeader = [
    { component: null },
    {
        component: 'Free',
    },
    { component: <PremiumHeader /> },
]

const firstColumnStyle: React.CSSProperties = {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: '1rem',
}

const plansRows = [
    [
        {
            component: 'Daily news coverage',
            style: firstColumnStyle,
        },
        { component: AvailableForFreeIcon },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Finimize Daily Brief newsletter',
            style: firstColumnStyle,
        },
        { component: AvailableForFreeIcon },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Educational guides and deep dives',
            style: firstColumnStyle,
        },
        { component: AvailableForFreeIcon },
        { component: AvailableForPremiumIcon },
    ],

    [
        {
            component: 'Analyst research & opportunities',
            style: firstColumnStyle,
        },
        { component: null },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Daily charts & data analysis',
            style: firstColumnStyle,
        },
        { component: null },
        { component: AvailableForPremiumIcon },
    ],

    [
        {
            component: 'Stock Data & Watchlists in app',
            style: firstColumnStyle,
        },
        { component: null },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Listen along to content in app',
            style: firstColumnStyle,
        },
        { component: null },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Premium analyst newsletter',
            style: firstColumnStyle,
        },
        { component: null },
        { component: AvailableForPremiumIcon },
    ],
    [
        {
            component: 'Finimize community event access',
            style: firstColumnStyle,
        },
        { component: AvailableForFreeIcon },
        { component: AvailableForPremiumIcon },
    ],
]

export default Plans
