import type { NextPageContext } from 'next'
import { fetchQuery } from 'react-relay'

import initEnvironment from './createRelayEnvironment'
import handleAuth from './handleAuth'

import sitemap from 'lib/sitemap'
import type { BuildSitemapParams } from 'lib/sitemap/types'
import { routeMap } from 'shared/routes'

interface StaticInterface<P> {
    getInitialProps?: (context: any) => Promise<P>
}

export type GetSitemapDataFromRelayQuery = BuildSitemapParams

type Options<P> = {
    redirectKeyIfNoAuth?: string
    shouldRedirectIfLoggedIn?: boolean
    [key: string]: any
    getSitemapDataFromRelayQuery?: (queryProps: P) => GetSitemapDataFromRelayQuery
    /**
     * Optional function designed to be used as the "getInitialProps" function for a Page
     * wrapped by withData (as they can't export their own).
     * Use cases at this time are content page 301 redirects for SEO purposes and
     * applying correct 404 status code for pages where data is not found.
     *
     * @param context
     * @param queryProps
     * @returns {Promise<boolean>} - true if page was redirected, false otherwise. This is important as it allows for a redirect to be handled properly by withData
     */
    getInitialPropsPage?: (
        context: NextPageContext,
        queryProps: P | null,
    ) => Promise<boolean> | boolean
}

type Context = {
    query?: { [key: string]: any }
} & NextPageContext

const withData = <P extends object>(
    ComposedComponent: React.ComponentType<P> & StaticInterface<P>,
    options: Options<P> = {},
) => {
    const WithData = (props: P & { queryRecords?: any }) => {
        return <ComposedComponent {...(props as P)} />
    }

    WithData.displayName = `WithData(${ComposedComponent.displayName})`

    WithData.getInitialProps = async (context: Context) => {
        let composedInitialProps = {}

        if (ComposedComponent.getInitialProps) {
            composedInitialProps = await ComposedComponent.getInitialProps(context)
        }

        let queryProps: P | null = null
        let queryRecords = {}

        const environment = initEnvironment({ initialRecords: {}, context })

        const didRedirect = await handleAuth(context, environment, options)
        if (didRedirect) {
            return {}
        }

        if (options.query) {
            // Provide the `url` prop data in case a graphql query uses it
            // const url = { query: context.query, pathname: context.pathname }
            const variables = options.mapProps ? options.mapProps(context) : {}

            // https://relay.dev/docs/api-reference/fetch-query/#behavior-with-topromise
            // @ts-ignore Note toPromise is not recommended by Relay and will break with future functionality
            queryProps = await fetchQuery(environment, options.query, variables).toPromise()

            queryRecords = environment.getStore().getSource().toJSON()
        }

        // Allow for a page to be redirected using a custom middleware
        // Should eventually moved out of here, but this method should require minimal refactor
        if (!!options.getInitialPropsPage) {
            const didRedirect = await options.getInitialPropsPage(context, queryProps)
            if (didRedirect) {
                return {}
            }
        }

        // This is for when a page is to be a sitemap.
        // getSitemapDataFromRelayQuery() is a function to preprocess data from
        // a relay query. The data returned from the function is then used to render
        // the sitemap.
        if (!!options.getSitemapDataFromRelayQuery && !!context.res) {
            if (!queryProps) {
                return {}
            }
            const sitemapConfig = options.getSitemapDataFromRelayQuery(queryProps)
            sitemap.render({ res: context.res, ...sitemapConfig })
            return {}
        }

        return {
            ...context.query, // query info from next-router. E.g "slug"
            ...composedInitialProps,
            ...queryProps,
            queryRecords,
        }
    }

    return WithData
}

export default withData

// use this if the page requires a logged-in-user
// if user is NOT logged in, he/she will be redirected
export const withDataLoggedIn = <P extends object>(
    Component: React.ComponentType<P> & StaticInterface<P>,
    graphQLconfig: Options<P>,
    redirectKeyIfNoAuth: string = routeMap.home,
) => withData(Component, { ...graphQLconfig, redirectKeyIfNoAuth })

// use this if the page requires a not-logged-in-user
// if user IS logged in, he/she will be redirected according to some common logic in handleAuth
export const withDataNotLoggedIn = <P extends object>(
    Component: React.ComponentType<P> & StaticInterface<P>,
    graphQLconfig?: Options<P>,
    shouldRedirectIfLoggedIn = true,
    // might want to add an override key here at some point if we need it
) => withData(Component, { ...graphQLconfig, shouldRedirectIfLoggedIn })
