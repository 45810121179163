import { config } from '../../sitemap.config'

import { NEWS_TAG_NAMES, TAG_NAMES } from './consts'
import type {
    NewsBlockConfig,
    UrlBlockConfig,
    SitemapBlockConfig,
    UrlBlockWithNewsConfig} from './types';
import {
    hasUrlLink,
} from './types'

import type { XMLTag } from 'lib/xml-parser/types'

// create a JSON config for a sitemap index
export const createSitemapIndexConfig = (sitemaps: SitemapBlockConfig[]) => [
    {
        tag: TAG_NAMES.SITEMAP_INDEX,
        attrs: [{ key: 'xmlns', value: 'http://www.sitemaps.org/schemas/sitemap/0.9' }],

        children: sitemaps.map(sitemap => ({
            tag: TAG_NAMES.SITEMAP,
            children: [
                { tag: TAG_NAMES.LOCATION, content: `${config.baseUrl}/${sitemap.extension}` },
            ],
        })),
    },
]

// create a JSON config for a url set
export const createUrlSetConfig = (pages: UrlBlockConfig[]) => [
    {
        tag: TAG_NAMES.URL_SET,
        attrs: [{ key: 'xmlns', value: 'http://www.sitemaps.org/schemas/sitemap/0.9' }],
        children: pages.map(e => createUrlConfig(e)),
    },
]

// create a JSON config for a url set with news
export const createNewsUrlSetConfig = (pages: UrlBlockConfig[]) => [
    {
        tag: TAG_NAMES.URL_SET,
        attrs: [
            { key: 'xmlns', value: 'http://www.sitemaps.org/schemas/sitemap/0.9' },
            { key: 'xmlns:news', value: 'http://www.google.com/schemas/sitemap-news/0.9' },
        ],
        children: pages.map(e => createUrlConfig(e, true)),
    },
]

const hasNews = (
    block: UrlBlockConfig | UrlBlockWithNewsConfig,
): block is UrlBlockWithNewsConfig => {
    return (block as UrlBlockWithNewsConfig).news !== undefined
}

// helper function for creating JSON for url
const createUrlConfig = (
    block: UrlBlockConfig | UrlBlockWithNewsConfig,
    showNews = false,
): XMLTag => {
    const children: XMLTag[] = []

    let content = ''

    // If no URL then there will be an extension
    if (hasUrlLink<typeof block>(block)) {
        content = block.url
    } else {
        content = `${config.baseUrl}/${block.extension.replace(/^\//, '')}`
    }

    children.push({
        tag: TAG_NAMES.LOCATION,
        content,
    })

    if (!!block.lastModified)
        children.push({ tag: TAG_NAMES.LAST_MODIFIED, content: block.lastModified })
    if (!!block.changeFrequency)
        children.push({ tag: TAG_NAMES.CHANGE_FREQUENCY, content: block.changeFrequency })
    if (!!block.priority)
        children.push({ tag: TAG_NAMES.PRIORITY, content: block.priority.toString() })
    if (showNews && hasNews(block)) children.push(createNewsConfig(block.news))

    return { tag: TAG_NAMES.URL, children }
}

const createNewsConfig = (news: NewsBlockConfig): XMLTag => {
    const children: XMLTag[] = []

    children.push({
        tag: NEWS_TAG_NAMES.PUBLICATION,
        children: [
            { tag: NEWS_TAG_NAMES.NAME, content: config.news.publication_name },
            { tag: NEWS_TAG_NAMES.LANGUAGE, content: config.news.default_language },
        ],
    })

    children.push({ tag: NEWS_TAG_NAMES.TITLE, content: news.title })
    children.push({ tag: NEWS_TAG_NAMES.PUBLICATION_DATE, content: news.publicationDate })

    if (!!news.genres) children.push({ tag: NEWS_TAG_NAMES.GENRES, content: news.genres.join() })
    if (!!news.keywords)
        children.push({
            tag: NEWS_TAG_NAMES.KEYWORDS,
            content: news.keywords.concat(config.news.default_keywords).join(),
        })
    if (!!news.stockTickers)
        children.push({ tag: NEWS_TAG_NAMES.STOCK_TICKERS, content: news.stockTickers.join() })

    return { tag: NEWS_TAG_NAMES.NEWS, children }
}
