import type { XMLTag, XMLAttributes } from './types'

/**
 * Parsing function derived from a complex and poorly documented node package.
 * With the function we can compile xml document of any shape using any tags.
 *
 * This simplifies the funcitonality and makes it easier to understand what is going
 * on than using regex.
 *
 * It takes a config for an XMLTag and recursively calles itself until all the children
 * have been resolved.
 *
 * A depth of 6 has been used arbitrarily just to protect from memory leaking.
 *
 * Example:
 *  Input:
 *      [{
 *          tag: 'dog',
 *          attrs: [{ key: 'vaccinated', value: 'yes' }],
 *          children: [
 *              {
 *                  tag: 'woof',
 *                  content: 'loud'
 *              }
 *          ]
 *      }]
 *
 * Output:
 *     <dog vaccinated="yes">
 *         <woof>loud</woof>
 *     </dog>
 *
 */
export const parseJSONtoXML = (config?: XMLTag[], depth = 0): string => {
    if (depth === 7) return ''

    if (!config) return ''

    const xml = config.map(e => parseXMLTag(e, depth)).join('')

    return xml
}

/**
 * Private helper function used by parseJSONtoXML to resolve a json config.
 */
const parseXMLTag = (tagConfig: XMLTag, depth = 0) => {
    // resolve any attributes
    const parseAttrs = (attrs?: XMLAttributes[]) => {
        if (!attrs) return ''
        return attrs.map(a => `${a.key}="${a.value}"`).join(' ')
    }

    // resolve any children by calling parseJSONtoXML (recursive)
    const children = parseJSONtoXML(tagConfig.children, depth + 1)

    const hasInnerXML = !!children || !!tagConfig.content
    const hasAttributes = !!tagConfig.attrs

    let partialTag = `<${tagConfig.tag}`

    // add attributes
    if (hasAttributes) partialTag += ' ' + parseAttrs(tagConfig.attrs)

    const parsedContent = parseSpecialCharacters(tagConfig.content)

    // compile either as a self closing tag or with all the other components
    const parsedXML = !hasInnerXML
        ? partialTag + '/>'
        : `${partialTag}>${parsedContent}${children}</${tagConfig.tag}>`

    return parsedXML
}

const parseSpecialCharacters = (str?: string) => {
    if (!str) return ''
    return str
        .replace(/&/g, '&amp;amp;') //<= start with
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;')
}
