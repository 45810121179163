import PriceBoxes from 'components/PriceBoxes'
import type { Pricing } from 'lib/pricing/types'

type PricingUIProps = {
    pricingData: Pricing
}

const PricingUI = ({ pricingData }: PricingUIProps) => {
    return (
        <PriceBoxes
            yearly={pricingData.yearly}
            monthly={pricingData.monthly}
            setSelectedPrice={null}
        />
    )
}

export default PricingUI
