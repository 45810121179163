import { parseJSONtoXML } from '../xml-parser/parseJSONtoXML'

import { createNewsUrlSetConfig, createSitemapIndexConfig, createUrlSetConfig } from './configs'
import { PAGE_HEADER } from './consts'
import type {
    BuildSitemapParams,
    RenderSitemapParams,
    SitemapBlockConfig,
    UrlBlockConfig,
    UrlBlockWithNewsConfig,
} from './types'

/**
 * This takes a config, calls buildSitemap, and writes it to ServerResponse.
 *
 * Additionally it sets the XML header and ends the response. Therefore, this
 * is a TERMINATION function.
 *
 * See buildSitemap to see how configs are priorities.
 *
 * NOTE: This must be used as the exclusive output of the page.
 */
export const renderSitemap = ({ res, ...buildParams }: RenderSitemapParams) => {
    res.statusCode = 200
    res.setHeader('Content-Type', 'text/xml')
    res.write(buildSitemap(buildParams))
    res.end()
}

/**
 * This builds the xml sitemap given a config, and returns it as a string.
 *
 * Only one config will be build per sitemap. Therefore if you pass a pageConfigList and a sitemapConfig,
 * the pageConfigList will be prioritised and built.
 */
export const buildSitemap = ({ __typename, config }: BuildSitemapParams) => {
    if (__typename === 'urls') {
        const urlJSON = createUrlSetConfig(config as UrlBlockConfig[])
        return PAGE_HEADER + parseJSONtoXML(urlJSON)
    }

    if (__typename === 'sitemap') {
        const sitemapJSON = createSitemapIndexConfig(config as SitemapBlockConfig[])

        return PAGE_HEADER + parseJSONtoXML(sitemapJSON)
    }

    if (__typename === 'news') {
        const newsJSON = createNewsUrlSetConfig(config as UrlBlockWithNewsConfig[])
        return PAGE_HEADER + parseJSONtoXML(newsJSON)
    }

    return PAGE_HEADER
}

const sitemap = {
    render: renderSitemap,
    build: buildSitemap,
}

export default sitemap
