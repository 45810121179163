/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type JoinPageQueryVariables = {};
export type JoinPageQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"useTagCloud_viewer" | "usePricing_viewer" | "Pricing_viewer">;
    };
};
export type JoinPageQuery = {
    readonly response: JoinPageQueryResponse;
    readonly variables: JoinPageQueryVariables;
};



/*
query JoinPageQuery {
  viewer {
    ...useTagCloud_viewer
    ...usePricing_viewer
    ...Pricing_viewer
    id
  }
}

fragment Pricing_viewer on Viewer {
  ...usePricing_viewer_3BSJ2K
}

fragment usePricing_PricingConfig on PricingConfig {
  stripeId
  amount
  currency
  currencySymbol
  trialDays
  discount {
    discountPct
    couponDurationInMonths
    couponRepetition
    discountFixed {
      currencySymbol
      amount
    }
    id
  }
}

fragment usePricing_viewer on Viewer {
  webPricingConfig {
    name
    yearly {
      ...usePricing_PricingConfig
      id
    }
    monthly {
      ...usePricing_PricingConfig
      id
    }
    id
  }
}

fragment usePricing_viewer_3BSJ2K on Viewer {
  webPricingConfig {
    name
    yearly {
      ...usePricing_PricingConfig
      id
    }
    monthly {
      ...usePricing_PricingConfig
      id
    }
    id
  }
}

fragment useTagCloud_viewer on Viewer {
  mostPopularTags(first: 15) {
    edges {
      node {
        __typename
        ... on AssetClassTag {
          slug
          name
        }
        ... on ContentTag {
          slug
          name
        }
        ... on GeographyTag {
          slug
          name
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencySymbol",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stripeId",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "trialDays",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "StripeDiscount",
    "kind": "LinkedField",
    "name": "discount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountPct",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "couponDurationInMonths",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "couponRepetition",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Price",
        "kind": "LinkedField",
        "name": "discountFixed",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useTagCloud_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "usePricing_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Pricing_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JoinPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "GenericTagConnection",
            "kind": "LinkedField",
            "name": "mostPopularTags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GenericTagNonNullEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v1/*: any*/),
                        "type": "AssetClassTag",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v1/*: any*/),
                        "type": "ContentTag",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v1/*: any*/),
                        "type": "GeographyTag",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "mostPopularTags(first:15)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebPricingConfig",
            "kind": "LinkedField",
            "name": "webPricingConfig",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingConfig",
                "kind": "LinkedField",
                "name": "yearly",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingConfig",
                "kind": "LinkedField",
                "name": "monthly",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c73ecc8122a5fd77664d13f96d8ee11b",
    "id": null,
    "metadata": {},
    "name": "JoinPageQuery",
    "operationKind": "query",
    "text": "query JoinPageQuery {\n  viewer {\n    ...useTagCloud_viewer\n    ...usePricing_viewer\n    ...Pricing_viewer\n    id\n  }\n}\n\nfragment Pricing_viewer on Viewer {\n  ...usePricing_viewer_3BSJ2K\n}\n\nfragment usePricing_PricingConfig on PricingConfig {\n  stripeId\n  amount\n  currency\n  currencySymbol\n  trialDays\n  discount {\n    discountPct\n    couponDurationInMonths\n    couponRepetition\n    discountFixed {\n      currencySymbol\n      amount\n    }\n    id\n  }\n}\n\nfragment usePricing_viewer on Viewer {\n  webPricingConfig {\n    name\n    yearly {\n      ...usePricing_PricingConfig\n      id\n    }\n    monthly {\n      ...usePricing_PricingConfig\n      id\n    }\n    id\n  }\n}\n\nfragment usePricing_viewer_3BSJ2K on Viewer {\n  webPricingConfig {\n    name\n    yearly {\n      ...usePricing_PricingConfig\n      id\n    }\n    monthly {\n      ...usePricing_PricingConfig\n      id\n    }\n    id\n  }\n}\n\nfragment useTagCloud_viewer on Viewer {\n  mostPopularTags(first: 15) {\n    edges {\n      node {\n        __typename\n        ... on AssetClassTag {\n          slug\n          name\n        }\n        ... on ContentTag {\n          slug\n          name\n        }\n        ... on GeographyTag {\n          slug\n          name\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2aa0b05880d4b4cd7868023c81da45ae';
export default node;
