import type { CouponDurationEnum } from '__generated__/usePricing_PricingConfig.graphql'
import type { StripeInterval } from 'types/stripeConsts'
export type Pricing = {
    yearly: Price
    monthly: Price
}
// Need this for now until we fully migrate to the new config, where both yearly and monthly are required
export type LegacyPricing =
    | { yearly: Price; monthly: undefined }
    | { yearly: undefined; monthly: Price }

export type Price = {
    id: string
    amount: number
    currency: string
    currencySymbol: string
    interval: StripeInterval
    nickname: string
    promotionApplied: Discount | null
    trialDays: number
}

export type Discount = {
    discountPct: number
    discountAmount: number
    couponDurationInMonths: number
    couponRepetition: CouponDurationEnum
}

export const isPricing = (pricing: Pricing | LegacyPricing): pricing is Pricing => {
    return !!pricing.monthly && !!pricing.yearly
}
